<template>
  <v-card elevation="0" :loading="$store.state.loading" class="transparent">
    <v-card-title>
      شركات المكتب
      <v-spacer></v-spacer>
      <v-btn @click="$store.commit('companies/toggleDialog')" color="primary">
        <v-icon>mdi-plus</v-icon>
        إضافة شركة
      </v-btn>
    </v-card-title>
    <v-card-text>
      <h3 v-if="companies.length == 0" class="text-center">لا يوجد شركات</h3>
      <template v-else>
        <v-row>
          <v-col
            cols="12"
            md="4"
            v-for="company in companies"
            :key="company.id"
          >
            <v-card style="height: 100%">
              <v-card-title class="align-center align-content-center">
                <v-avatar
                  size="80"
                  rounded
                  v-if="company.logoUrl != null"
                  class="mx-5"
                >
                  <v-img
                    v-if="company.logoUrl != null"
                    contain
                    :src="company.logoUrl"
                  ></v-img>
                </v-avatar>

                <h4>{{ company.name }}</h4>
                <v-spacer></v-spacer>
                <v-btn small @click="editCompany(company)" color="primary" text>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="12" class="subtitle-1">
                    <v-icon>mdi-package-variant-closed</v-icon>
                    تاريخ الاشتراك :
                    <span>{{
                      $service.formatDate(
                        new Date(company.subscriptionDate),
                        false
                      )
                    }}</span>
                  </v-col>
                  <v-col cols="12" class="subtitle-1">
                    <v-icon>mdi-currency-usd</v-icon>
                    سعر الاشتراك : <span>{{ $service.formatNumber(company.subscriptionPrice) }}</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-card-text>
    <Dialog @refresh="getCompanies()" />
  </v-card>
</template>
<script>
import Dialog from "./Dialog.vue";
export default {
  props: ["officeId"],
  components: {
    Dialog,
  },
  data() {
    return {
      companies: [],
    };
  },
  methods: {
    editCompany(office) {
      let temp = office;
      this.$store.commit("companies/updateItem", temp);
      this.$store.commit("companies/toggleEdit");
    },
    getCompanies() {
      this.$store.commit("setLoading", true);
      this.$http
        .get("/Companies", {
          params: {
            OfficeId: this.officeId,
          },
        })
        .then((res) => {
          this.companies = res.data.result;
          this.$store.commit("offices/updateCompanies", res.data.result);
          this.$store.commit("setLoading", false);
        });
    },
  },
  created() {
    this.getCompanies();
  },
};
</script>