<template>
  <v-dialog persistent v-model="$store.state.users.addSupervisor" width="650">
    <v-card :loading="$store.state.loading" outlined>
      <v-card-title class="text-h5 my-3">
        اضافة مشرف
        <v-spacer></v-spacer>
        <v-btn
          @click="$store.commit('users/toggleDialog', 'addSupervisor')"
          text
          color="error"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-form
        ref="addSupervisor"
        :disabled="$store.state.loading"
        v-model="vaild"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                hide-details="auto"
                label="الاسم"
                v-model="inputs.fullName"
                required
                :rules="[$rules.required()]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                hide-detials="auto"
                append-icon="mdi-phone"
                v-model="inputs.phoneNumber"
                label="رقم الهاتف"
                dir="ltr"
                v-mask="'+964 ### ### ####'"
                :rules="[$rules.required()]"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                hide-details="auto"
                label="البريد الالكتروني"
                v-model="inputs.email"
                required
                :rules="[$rules.required(), $rules.emailFormat()]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                hide-details="auto"
                label="اسم المستخدم"
                v-model="inputs.userName"
                required
                :rules="[$rules.required()]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                hide-detials="auto"
                outlined
                v-model="inputs.password"
                :type="isPasswordShow ? 'text' : 'password'"
                :append-icon="isPasswordShow ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="isPasswordShow = !isPasswordShow"
                label="كلمة السر"
                :rules="[$rules.required()]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="inputs.supervisor.companyId"
                label="الشركة"
                outlined
                @change="getManagers()"
                :items="$store.state.offices.companies"
                item-text="name"
                item-value="id"
                :rules="[$rules.required()]"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="inputs.supervisor.managerId"
                label="المدير"
                outlined
                :loading="$store.state.loading"
                :items="managers"
                item-text="fullName"
                item-value="id"
                no-data-text="لا يوجد مدراء"
                :disabled="!inputs.supervisor.companyId"
                :rules="[$rules.required()]"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                label="المحافظة"
                :loading="$store.state.loading"
                :items="provinecs"
                outlined
                item-text="name"
                item-value="id"
                v-model="inputs.provinecId"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-file-input
                v-model="file"
                label="الصورة"
                outlined
                hide-details
                accept="image/*"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="$store.commit('users/toggleDialog', 'addSupervisor')"
          >
            إلغاء
          </v-btn>
          <v-btn color="primary" :disabled="!vaild" @click="addSupervisor()">
            حفظ
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  computed: {
    isEdit() {
      return this.$store.state.users.isEdit;
    },
    item: {
      get() {
        return this.$store.state.users.item;
      },
    },
  },
  data() {
    return {
      date: false,
      vaild: false,
      isPasswordShow: false,
      file: null,
      managers: [],
      provinecs: [],
      inputs: {
        fullName: "",
        userName: "",
        email: "",
        password: "",
        phoneNumber: "",
        enrolledDate: new Date().toISOString().substr(0, 10),
        salary: 0,
        imageUrl: "",
        provinecId: "",
        addres: {
          city: "",
          region: "",
          street: "",
          zipCode: "",
          closestPoint: "",
        },
        userAttachement: [],
        supervisor: {
          companyId: "",
          managerId: "",
        },
      },
    };
  },
  methods: {
    addSupervisor() {
      this.$store.commit("setLoading", true);
      this.$store.dispatch("uploadFile", this.file).then((url) => {
        if (typeof url === "string") {
          this.inputs.imageUrl = url;
        }
        if (typeof this.inputs.logoUrl === "object") {
          this.inputs.imageUrl = "";
        }
        this.inputs.imageUrl = this.inputs.imageUrl.replace(
          "https://api.scodefield.com/"
        );
        this.inputs.officeId = this.$route.params.id;
        this.$http.post("/Supervisors/post", this.inputs).then(() => {
          this.$store.commit("setLoading", false);
          this.$store.commit("users/toggleDialog", "addSupervisor");
          this.$emit("refresh", true);
        });
      });
    },
    getManagers() {
      this.$store.commit("setLoading", true);
      this.$http
        .get(`/Managers?CompanyId=${this.inputs.supervisor.companyId}`)
        .then((res) => {
          this.managers = res.data.data;
          this.$store.commit("setLoading", false);
        });
    },
  },
  created() {
    this.$store.commit("setLoading", true);
    this.$http("/provinecs").then((res) => {
      this.provinecs = res.data.result;
      this.$store.commit("setLoading", false);
    });
  },
  watch: {
    "$store.state.users.addSupervisor"() {
      if (this.$store.state.users.dialog == false) {
        if (this.$store.state.users.isEdit) {
          this.$store.commit("users/toggleEdit", "addSupervisor");
        } else {
          this.$refs.addSupervisor.reset();
        }
      } else {
        if (!this.$store.state.users.isEdit) {
          this.$refs.addSupervisor.reset();
        }
      }
      if (this.isEdit) {
        this.inputs = this.item;
        let fileName = this.item.logoUrl.split("_");
        fileName = fileName[fileName.length - 1];
        this.file = new File(["1"], fileName);
      }
    },
  },
};
</script>
<style scoped>
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin: 0px !important;
}
.v-input__slot {
  margin-bottom: 0px !important;
  padding: 0px !important;
}
</style>