<template>
  <v-card elevation="0" class="transparent" :loading="$store.state.loading">
    <v-row>
      <v-col cols="12" md="7">
        <v-card  :loading="$store.state.loading">
          <v-card-title>
            <v-avatar size="75" tile>
              <img :src="$service.url + office.logoUrl" :alt="office.name" />
            </v-avatar>
            <div class="d-flex align-start flex-column mx-4">
              <span class="title">{{ office.name }}</span>
              <span class="subtitle-1">{{ office.descrption }}</span>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex flex-column align-center">
              <v-chip :color="office.isActive ? 'success' : 'error'" text>
                {{ office.isActive ? "مفعل" : "غير مفعل" }}
              </v-chip>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row align="center" class="d-flex justify-center align-center">
              <v-col cols="4" class="subtitle-1">
                <v-icon>mdi-office-building-marker</v-icon>
                الشركات الحالية : <span>{{ office.companiesCount }}</span>
              </v-col>
              <v-col cols="4" class="subtitle-1">
                <v-icon>mdi-truck-delivery</v-icon>
                المركبات : <span>{{ office.carsCount }}</span>
              </v-col>
              <v-col cols="4" class="subtitle-1">
                <v-icon>mdi-account-multiple</v-icon>
                الاعضاء : <span>{{ office.usersCount }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="5">
        <v-card style="height: 100%" :loading="$store.state.loading">
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn color="secondary" dark small>
              <v-icon>mdi-pencil</v-icon>
              تعديل كلمة السر
            </v-btn>

            <v-btn text @click="editOwner(owner)" color="primary">
              <v-icon centered>mdi-pencil</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text v-if="owner != null">
            <div class="d-flex align-center flex-column mb-5">
              <v-avatar tile size="90">
                <v-icon v-if="owner.imageUrl == null" size="100">
                  mdi-account-circle
                </v-icon>
                <img v-else :src="owner.imageUrl" :alt="owner.name" />
              </v-avatar>
              <h3>
                {{ owner.fullName }}
              </h3>
              <h4>
                {{ owner.userName }}
              </h4>
            </div>
            <v-divider></v-divider>
            <div class="d-flex align-start justify-space-around mt-2">
              <span class="d-flex align-center subtitle-2">
                <v-icon>mdi-phone</v-icon>
                <span dir="ltr">
                  {{ owner.phoneNumber }}
                </span>
              </span>
              <span class="d-flex align-center subtitle-2">
                <v-icon>mdi-email</v-icon>
                {{ owner.email }}
              </span>
            </div>
            <div class="d-flex align-start justify-space-around mt-2">
              <span class="d-flex align-center subtitle-2">
                <v-icon>mdi-currency-usd</v-icon>
                {{ owner.salary }}
              </span>
              <span class="d-flex align-center subtitle-2">
                <v-icon>mdi-calendar-month</v-icon>
                {{ $service.formatDate(new Date(owner.created), false) }}
              </span>
            </div>
          </v-card-text>
          <v-card-text v-else>
            <div class="text-center d-flex flex-column align-center mt-10">
              <h4>لم يتم ربط المكتب مالك</h4>
              <v-btn
                class="mt-2"
                color="secondary"
                @click="$store.commit('offices/toggleOwnerDialog')"
              >
                <v-icon>mdi-account-plus</v-icon>
                ربط المكتب مالك
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <companies-card :officeId="officeId" />
      </v-col>
      <v-col cols="12">
        <users-card />
      </v-col>
    </v-row>
    <add-owner @refresh="getOffice()" />
  </v-card>
</template>
<script>
import AddOwner from "./AddOwner.vue";
import companiesCard from "./companies/CompaniesCard.vue";
import UsersCard from "./users/UsersCard.vue";
export default {
  name: "Office",
  components: {
    companiesCard,
    AddOwner,
    UsersCard,
  },
  computed: {
    officeId() {
      return this.$route.params.id;
    },
    owner() {
      if (this.office.users !== undefined) {
        if (this.office.users.filter((v) => v.role == "Owner").length > 0) {
          return this.office.users.filter((v) => v.role == "Owner")[0];
        }
      }
      return null;
    },
  },
  data() {
    return {
      office: {},
    };
  },
  methods: {
    editOwner(temp) {
      this.$store.commit("offices/updateItem", temp);
      this.$store.commit("offices/toggleEdit", 'ownerDialog');
      this.$store.commit('offices/toggleOwnerDialog')
    },
    getOffice() {
      this.$store.commit("setLoading", true);
      this.$http.get(`/offices/${this.officeId}`).then((response) => {
        this.office = response.data.result;
        this.$store.commit("setLoading", false);
      });
    },
  },
  created() {
    this.getOffice();
  },
};
</script>