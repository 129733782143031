<template>
<div>
    <v-card :loading="$store.state.loading">
        <v-card-title>
            <span>الاعضاء</span>
            <v-spacer></v-spacer>
            <div>
                <v-btn @click="$store.commit('users/toggleDialog', 'addManager')" v-if="userType == 0" large color="primary">
                    <v-icon>mdi-plus</v-icon>
                    اضافة مدير
                </v-btn>
                <v-btn @click="$store.commit('users/toggleDialog', 'addAccounts')" v-if="userType == 1" large color="primary">
                    <v-icon>mdi-plus</v-icon>
                    اضافة حسابات
                </v-btn>
                <v-btn @click="$store.commit('users/toggleDialog', 'addSupervisor')" v-if="userType == 2" large color="primary">
                    <v-icon>mdi-plus</v-icon>
                    اضافة مشرف
                </v-btn>
                <v-btn v-if="userType == 3" @click="$store.commit('users/toggleDialog', 'addTeamleader')" large color="primary">
                    <v-icon>mdi-plus</v-icon>
                    اضافة موجه
                </v-btn>
            </div>
            <v-tabs v-model="userType">
                <v-tab value="Managers">المدراء</v-tab>
                <v-tab value="Accounts">الحسابات</v-tab>
                <v-tab value="Supervisors">المشرفين</v-tab>
                <v-tab value="Te">الموجهين</v-tab>
            </v-tabs>
        </v-card-title>
        <v-card-text>
            <v-data-table :headers="headers" :ripple="false" :items="users" hide-default-footer :loading="$store.state.loading" no-data-text="لا يوجد" loading-text="جار التحميل...">
                <!-- <template v-slot:item.actions="{ item }"> -->
                <!-- <v-btn @click="editItem(item)" text>
              <v-icon>mdi-pencil</v-icon>
            </v-btn> -->
                <!-- </template> -->
                <template v-slot:item.created="{ item }">
                    <span dir="ltr">
                        {{ $service.formatDate(new Date(item.created), false) }}
                    </span>
                </template>
                <template v-slot:item.phoneNumber="{ item }">
                    <span dir="ltr">
                        {{ item.phoneNumber }}
                    </span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn color="secondary" @click="editPassword(item)" dark small>
                        <v-icon>mdi-pencil</v-icon>
                        تعديل كلمة السر
                    </v-btn>
                </template>
                <template v-slot:item.email="{ item }">
                    <span dir="ltr">
                        {{ item.email }}
                    </span>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
    <add-manager @refresh="getUsers()" />
    <add-accounts @refresh="getUsers()" />
    <add-supervisor @refresh="getUsers()" />
    <add-teamleader @refresh="getUsers()" />
    <change-password @refresh="getUsers()" />
</div>
</template>

<script>
import AddAccounts from "./AddAccounts.vue";
import AddManager from "./AddManager.vue";
import AddSupervisor from "./AddSupervisor.vue";
import AddTeamleader from "./AddTeamleader.vue";
import ChangePassword from "@/views/components/ChangePassword.vue";
export default {
    components: {
        AddManager,
        ChangePassword,
        AddAccounts,
        AddSupervisor,
        AddTeamleader,
    },
    data() {
        return {
            users: [],
            userType: 0,
            userTypes: [
                ["M1", "M2", "M3", "M4"],
                ["Ac"],
                ["S"],
                ["TL1", "TL2"]
            ],
            options: {
                OfficeId: this.$route.params.id,
                Search: "",
            },
            headers: [{
                    text: "الاسم",
                    value: "fullName",
                },
                {
                    text: "اسم المستخدم",
                    value: "userName",
                },
                {
                    text: "رقم الهاتف",
                    value: "phoneNumber",
                },
                {
                    text: "البريد الالكتروني",
                    value: "email",
                },
                {
                    value: "role",
                    text: "الدور",
                },
                {
                    text: "الراتب",
                    value: "salary",
                },
                {
                    text: "التاريخ",
                    value: "created",
                },
                {
                    text: "الاجراءات",
                    value: "actions",
                },
            ],
        };
    },
    methods: {
        editPassword(item) {
            this.$store.commit("setUser", item);
            this.$store.commit("togglePasswordDialog");
        },
        editItem(item) {
            let temp = item;
            let dialog = "addManager";
            this.$store.commit("users/updateItem", temp);
            this.$store.commit("users/toggleEdit", dialog);
        },
        getUsers() {
            this.$store.commit("setLoading", true);
            let type = this.userTypes[this.userType];
            let filter =
                "?" +
                Object.entries(this.options)
                .map(([key, val]) => {
                    if (val !== "" && val !== null) {
                        if (key != "Statuses") {
                            return `${key}=${val}`;
                        }
                    }
                })
                .join("&");

            type.forEach((element) => {
                filter += "&UserTypes=" + element;
            });
            this.$http.get("/users" + filter.replace("?&", "?")).then((response) => {
                this.users = response.data.data;
                this.$store.commit("setLoading", false);
            });
        },
    },
    created() {
        this.getUsers();
    },
    watch: {
        userType() {
            this.getUsers();
        },
    },
};
</script>
